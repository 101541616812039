import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/Firebase';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import axiosInstance, { getAuthToken } from 'helpers/axios';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  CHECK_VERIFICATION,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  verifyEmailSuccess,
  verifyEmailError,
  // checkVerificaitonSuccess,
  // checkVerificaitonError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

const { SERVER_HOST } = require('constants/defaultValues');

const getAccount = async (email) => {
  console.log('emailId', email);
  try {
    const token = await getAuthToken();
    const result = await axiosInstance.post(
      `${SERVER_HOST}/api/v1/teams/account`,
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchVerifyEmail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(VERIFY_EMAIL, verifyEmail);
}

const verifyEmailAsync = async () => {
  // eslint-disable-next-line no-return-await
  return await auth.currentUser
    .sendEmailVerification()
    .then(() => {
      // Email verification sent!
      // ...
    })
    .catch((error) => error);
};

function* verifyEmail({ payload }) {
  const { email } = payload.verifyUserMail;
  try {
    const verifyEmailStatus = yield call(verifyEmailAsync, email);
    if (!verifyEmailStatus) {
      yield put(verifyEmailSuccess('success'));
    } else {
      yield put(verifyEmailError(verifyEmailStatus.message));
    }
  } catch (error) {
    yield put(verifyEmailError(error));
  }
}

export function* watchCheckVerificaiton() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(CHECK_VERIFICATION, checkVerificaiton);
}

const checkVerificationAsync = async () => {
  // eslint-disable-next-line no-return-await

  await auth.currentUser.reload();
  return auth.currentUser.emailVerified;
};

function* checkVerificaiton({ payload }) {
  const { history } = payload;
  try {
    console.log('Check Verification');
    const verifyEmailStatus = yield call(checkVerificationAsync);
    if (verifyEmailStatus) {
      console.log('Email Verified');
      clearInterval(window.intervalId);
      history.push(adminRoot);
    } else {
      console.log('Email Not Verified');
    }
  } catch (error) {
    // yield put(checkVerificaitonError(error));
  }
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      console.log('currentUser', loginUser.user);
      const item = {
        uid: loginUser.user.uid,
        ...currentUser,
        user: loginUser.user,
      };
      yield put(loginUserSuccess(item));
      if (loginUser.user.emailVerified) {
        const account = yield call(getAccount, loginUser.user.email);
        setCurrentUser({ ...item, user: account });
        console.log('User Account:', account);
        if (account.individual) {
          console.log('Individual Login');
          // eslint-disable-next-line no-underscore-dangle
          history.push(`/app/pages/people/person/${account?._id}`);
        } else {
          history.push(adminRoot);
        }
      } else {
        const verification = yield call(verifyEmailAsync, loginUser.user);
        console.log('verificaiton', verification);
        history.push(`/user/verify-email`);
      }
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchVerifyEmail),
    fork(watchCheckVerificaiton),
    fork(watchResetPassword),
  ]);
}
