/* eslint-disable no-return-await */
import axios from 'axios';
// import { getCurrentUser } from './Utils';
import { auth } from './Firebase';
// const email = getEmail();

const axiosOptions = {
  timeout: 500000,
  headers: {},
};
const axiosInstance = axios.create(axiosOptions);
async function getToken() {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken(false);
    window.localStorage.token = token;
  }
  return window.localStorage.token;
}
async function getCurrentUser() {
  return auth.currentUser;
}
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const getAuthToken = getToken;
export const getAuthCurrentUser = getCurrentUser;
export default axiosInstance;
